/*=================================================================
  NAVIGATION
==================================================================*/
.navigation {
  transition: .3s all;
  background: #1f2532;
  .navbar-nav > li {
    &.active {
      a {
        color:$white;
      }
    }
     > a {
      color: #707d8f;
      font-size: 15px;
      line-height: 26px;
      padding: 20px 15px!important;
      transition: .2s ease-in-out 0s;
      @include mobile {
        padding:8px 0!important;
      }
      @include tablet {
        padding:8px 0!important;
      }
      &:hover, &:active, &:focus{
        background: none;
        color: $primary-color;
      }
    }
  }
  .navbar-toggler {
    color:$white;
    font-size:30px;
    cursor: pointer;
    outline: none;
  }
  .nav .open>a {
    background: none;
  }
  .dropdown {
    padding:0;
    &:hover {
        .dropdown-menu {
            visibility: visible;
            opacity: 1;
            transform: scaleY(1);
        }
    }
    .dropdown-item {
      padding:8px 15px;
      color:$white;
      font-size:13px;
      &:hover {
        background: transparent;
        color:$primary-color;
      }
    }
    .dropdown-menu {
      border-radius: 0;
      background-color: #000;
      display: block;
      visibility: hidden;
      transition: .3s ease;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;

      @include desktop {
          display: none;
          opacity: 1;
          visibility: visible;
          transform: scaleY(1);
          transform-origin: unset;
      }

      &.show {
          visibility: visible;
          opacity: 1;
          transform: scaleY(1);

          @include desktop {
              display: block;
          }
      }
    }
  }
  
}




.navbar-brand {
  height: auto;
  padding: 8px 0;
  color:$primary-color;
  img{
    width: 150px;
  }
}

.navbar-brand h1 {
  margin: 0;
    font-size: 25px;
}

.top-header {
    background: $black;
    padding:10px 0;
    overflow: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      height: 5px;
    }
    
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    
    &::-webkit-scrollbar-thumb {
      background-color: #252d34;
      outline: 2px solid #fff
    }
}

.currency-status {
    text-align: center;
    li {
        display: inline-block;
        margin:0 10px;
        &:last-child {
            a {

                border-right:1px solid #444;
            }

        }
        a {
            font-weight: normal;
            font-size: 14px;
            padding:0 15px;
            display: inline-block;
            color:#cacaca;
            border-left:1px solid #444;
            i {
                margin-left:4px;
            }
            .down-status {
                color: #ED522E;
            }
            .up-status {
                color: #70D70D;
            }
        }
    }
}


