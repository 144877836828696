
.no-padding {
  padding:0!important;
}
.inline-block {
  display: inline-block;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

a {
  transition: all .3s ease-in 0s;
}

iframe {
  border: 0;
}

a, a:focus, a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus, a:hover {
  color: $primary-color;
}

.btn-main {
  background-color: $primary-color;
  color: $light;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 10px 40px;
  text-transform: uppercase;
  border:1px solid transparent;
  border-radius:2px;
  &:hover {
    color: $light;
  }
}

.btn-main-sm {
  @extend .btn-main;
  padding:8px 20px;
  font-size:12px;
}


.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}


.btn:focus {
  color: #ddd;
}
#home {
  position: relative;
}

.section {
  padding:100px 0;
  @include mobile {
    padding:40px 0;
  }
}
.padding-0 {
  padding:0;
  @include tablet {
    padding:0 15px;
  }
}
.section-sm {
  padding: 70px 0;
}
.section-xs {
  padding: 40px 0;
}

.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}





#preloader {
  background: $primary-color;
  height: 100%;
  opacity: 1;
  filter: alpha(opacity=100);
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
}


.preloader {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.preloader .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.preloader .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: $white;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.preloader .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.preloader .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.preloader .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.preloader .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.preloader .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.preloader .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.preloader .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.preloader .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.preloader .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.preloader .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.preloader .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.preloader .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.preloader .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.preloader .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.preloader .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.preloader .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.preloader .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.preloader .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.preloader .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.preloader .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.preloader .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.preloader .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


// Common Styles
.title {
  padding-bottom: 50px;
  text-align: center;
  h2 {
    font-size: 35px;
    color: #232323;
    text-transform: capitalize;
    margin-bottom:8px;
    font-weight: 700;
  }
  h4 {
    font-size: 10px;
    line-height: 10px;
    font-weight: 700;
    letter-spacing: 3.3px;
    text-transform: uppercase;
  }
  p {
    color: #848484;
    width: 50%;
    margin:0 auto;
    @include mobile {
      width: 80%;
    }
    @include tablet {
      width: 80%;
    }
  }
  .border {
    display: inline-block;
    height: 6px;
    position: relative;
    width: 90px;
    background: $primary-color;
    border-color:$primary-color;
    margin-bottom:10px;
  }
}

.bg-gray {
  background: #f9f9f9;
}
.color-gray {
  color:#777;
}

.shadow {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.34);
}

.gradient {
  background-image: linear-gradient(100deg, #f9643d, #fe2a77);
}

.header-bradcrumb {
  background: transparent;
  a , .active {
    color:#cfcfcf;
    font-weight: 200;
    font-family: $primary-font;
  }
  a:hover {
    color: $primary-color;
  }
}






.slick-slide {
  outline: none;
}

.commonTab {
  .nav-tabs {
    border:1px solid transparent;
    text-align: center;
    .nav-item {
      margin:0 3px;
        .nav-link {
          background: #f9f9f9;
          color:#666;
          text-transform: capitalize;
          border-radius:0;
          &.active {
            border:1px solid $primary-color;
            background: $primary-color;
            color:$white;
          } 
          &:hover {
            border:1px solid $primary-color;
            border-radius:0;
            background:$primary-color;
            color:$white;
          }
        }
      }
    }
    .tab-content {
      padding-top:30px;
    }
  }


.account {
    background: $primary-color;
    height:90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
        color:$white;
    }
    p {
        color:$white;

    }
    a {
        color:$white;
        font-weight: bold;
    }
    .block {
        form {
            background: $white;
            padding:30px;
            border-radius:4px;
            box-shadow: 0px 1px 46px -4px rgba(0,0,0,0.28);
        }
    }
}

