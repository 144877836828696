/*=================================================================
  About us section
==================================================================*/

.about {
  background: $light;
  h2 {
    font-size:40px;
    font-weight: bold;
    margin-top:0;
    @include desktop {
      font-size:25px;
    }
  }
  h4 {
    font-size:14px;
    color:$primary-color;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  p {
    line-height: 1.6;
    margin-top:20px;
    
  }
  .content {
    padding:50px 0;
    @include desktop {
      padding:5px 0;
    }

  }
}


/*=================================================================
  About us 2 section
==================================================================*/
.about-2 {
  background: #F7F7F7;
  h2 {
    margin-bottom:25px;
  }
  .bitcoin-graph{
    width: 100%;
    height: 400px;
  }
  .svg-container{
    overflow: hidden;
    @include desktop {
      width: 350px !important;
    }
    @include tablet {
      width: 450px !important;
    }
    @include mobile {
      width: 350px !important;
    }
    @include mobile-xs {
      width: 300px !important;
    }
  }
}