/*-------------------------------------------------------------------------------
TABLE OF CONTENT
-------------------------------------------------------------------------------*/


/*!------------------------------------------------------------------
[MAIN STYLESHEET]

PROJECT :	BitBank | Crypto Currency HTML Templates
VERSION :	1.0
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[TABLE OF CONTENTS]

1. BODY & TYPOGRAPHY

2. BACKGROUNDS

3. HEADER

4. NAVIGATION

5. HERO AREA

6. ABOUT US

7. CALL TO ACTION

8. CONTACT

9. COUNTER

10. PRICING

11. PORTFOLIO

12. SERVICE

13. SKILLS

14. TEAM

15. TESTIMONIAL

16. BLOG

17. SINGLE POST

18. FOOTER

19. 404_
-------------------------------------------------------------------*/


@import 'variables.scss';
@import 'typography.scss';
@import 'mixins.scss';
@import 'common.scss';
@import 'backgrounds.scss';
@import 'templates/header.scss';
@import 'templates/navigation.scss';
@import 'templates/hero-area.scss';
@import 'templates/about_us.scss';
@import 'templates/call-to-action.scss';
@import 'templates/contact.scss';
@import 'templates/counter.scss';
@import 'templates/pricing.scss';
@import 'templates/services.scss';
@import 'templates/skills.scss';
@import 'templates/team.scss';
@import 'templates/testimonials.scss';
@import 'templates/blog.scss';
@import 'templates/single-post.scss';
@import 'templates/footer.scss';
@import 'templates/404.scss';
