/*-------------------------------------------------------------------------------
TABLE OF CONTENT
-------------------------------------------------------------------------------*/
/*!------------------------------------------------------------------
[MAIN STYLESHEET]

PROJECT :	BitBank | Crypto Currency HTML Templates
VERSION :	1.0
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[TABLE OF CONTENTS]

1. BODY & TYPOGRAPHY

2. BACKGROUNDS

3. HEADER

4. NAVIGATION

5. HERO AREA

6. ABOUT US

7. CALL TO ACTION

8. CONTACT

9. COUNTER

10. PRICING

11. PORTFOLIO

12. SERVICE

13. SKILLS

14. TEAM

15. TESTIMONIAL

16. BLOG

17. SINGLE POST

18. FOOTER

19. 404_
-------------------------------------------------------------------*/
/*=================================================================
  TYPOGRAPHY
==================================================================*/
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,700|Roboto:400,500,700,900");
body {
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
}

p {
  font-family: "Roboto", sans-serif;
  color: #9a9a9a;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Playfair Display", serif;
  font-weight: 600;
}

/*=== MEDIA QUERY ===*/
.no-padding {
  padding: 0 !important;
}

.inline-block {
  display: inline-block;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

a {
  transition: all .3s ease-in 0s;
}

iframe {
  border: 0;
}

a, a:focus, a:hover {
  text-decoration: none;
  outline: 0;
}

a:focus, a:hover {
  color: #31BB9E;
}

.btn-main, .btn-main-sm {
  background-color: #31BB9E;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  padding: 10px 40px;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 2px;
}

.btn-main:hover, .btn-main-sm:hover {
  color: #fff;
}

.btn-main-sm {
  padding: 8px 20px;
  font-size: 12px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.btn:focus {
  color: #ddd;
}

#home {
  position: relative;
}

.section {
  padding: 100px 0;
}

@media (max-width: 480px) {
  .section {
    padding: 40px 0;
  }
}

.padding-0 {
  padding: 0;
}

@media (max-width: 768px) {
  .padding-0 {
    padding: 0 15px;
  }
}

.section-sm {
  padding: 70px 0;
}

.section-xs {
  padding: 40px 0;
}

.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}

#preloader {
  background: #31BB9E;
  height: 100%;
  opacity: 1;
  filter: alpha(opacity=100);
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
}

.preloader {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.preloader .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.preloader .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.preloader .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.preloader .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.preloader .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.preloader .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.preloader .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.preloader .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.preloader .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.preloader .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.preloader .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.preloader .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.preloader .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.preloader .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.preloader .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.preloader .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.preloader .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.preloader .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.preloader .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.preloader .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.preloader .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.preloader .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.preloader .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.preloader .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.title {
  padding-bottom: 50px;
  text-align: center;
}

.title h2 {
  font-size: 35px;
  color: #232323;
  text-transform: capitalize;
  margin-bottom: 8px;
  font-weight: 700;
}

.title h4 {
  font-size: 10px;
  line-height: 10px;
  font-weight: 700;
  letter-spacing: 3.3px;
  text-transform: uppercase;
}

.title p {
  color: #848484;
  width: 50%;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .title p {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .title p {
    width: 80%;
  }
}

.title .border {
  display: inline-block;
  height: 6px;
  position: relative;
  width: 90px;
  background: #31BB9E;
  border-color: #31BB9E;
  margin-bottom: 10px;
}

.bg-gray {
  background: #f9f9f9;
}

.color-gray {
  color: #777;
}

.shadow {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.34);
}

.gradient {
  background-image: linear-gradient(100deg, #f9643d, #fe2a77);
}

.header-bradcrumb {
  background: transparent;
}

.header-bradcrumb a, .header-bradcrumb .active {
  color: #cfcfcf;
  font-weight: 200;
  font-family: "Playfair Display", serif;
}

.header-bradcrumb a:hover {
  color: #31BB9E;
}

.slick-slide {
  outline: none;
}

.commonTab .nav-tabs {
  border: 1px solid transparent;
  text-align: center;
}

.commonTab .nav-tabs .nav-item {
  margin: 0 3px;
}

.commonTab .nav-tabs .nav-item .nav-link {
  background: #f9f9f9;
  color: #666;
  text-transform: capitalize;
  border-radius: 0;
}

.commonTab .nav-tabs .nav-item .nav-link.active {
  border: 1px solid #31BB9E;
  background: #31BB9E;
  color: #fff;
}

.commonTab .nav-tabs .nav-item .nav-link:hover {
  border: 1px solid #31BB9E;
  border-radius: 0;
  background: #31BB9E;
  color: #fff;
}

.commonTab .tab-content {
  padding-top: 30px;
}

.account {
  background: #31BB9E;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.account h2 {
  color: #fff;
}

.account p {
  color: #fff;
}

.account a {
  color: #fff;
  font-weight: bold;
}

.account .block form {
  background: #fff;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0px 1px 46px -4px rgba(0, 0, 0, 0.28);
}

/*=================================================================
  BACKGROUNDS
==================================================================*/
.bg-pricing {
  background: url("../images/pricing-bg.jpg");
  background-size: cover;
}

/*=================================================================
  SINGLE PAGE HEADER
==================================================================*/
.single-page-header {
  background: #1f2532;
  padding: 50px 0;
  text-align: center;
  color: #fff;
  position: relative;
}

.single-page-header .breadcrumb {
  background: transparent;
  display: inline-block;
}

.single-page-header .breadcrumb li {
  display: inline-block;
}

.single-page-header .breadcrumb li a {
  color: #31BB9E;
}

/*=================================================================
  NAVIGATION
==================================================================*/
.navigation {
  transition: .3s all;
  background: #1f2532;
}

.navigation .navbar-nav > li.active a {
  color: #fff;
}

.navigation .navbar-nav > li > a {
  color: #707d8f;
  font-size: 15px;
  line-height: 26px;
  padding: 20px 15px !important;
  transition: .2s ease-in-out 0s;
}

@media (max-width: 480px) {
  .navigation .navbar-nav > li > a {
    padding: 8px 0 !important;
  }
}

@media (max-width: 768px) {
  .navigation .navbar-nav > li > a {
    padding: 8px 0 !important;
  }
}

.navigation .navbar-nav > li > a:hover, .navigation .navbar-nav > li > a:active, .navigation .navbar-nav > li > a:focus {
  background: none;
  color: #31BB9E;
}

.navigation .navbar-toggler {
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  outline: none;
}

.navigation .nav .open > a {
  background: none;
}

.navigation .dropdown {
  padding: 0;
}

.navigation .dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  transform: scaleY(1);
}

.navigation .dropdown .dropdown-item {
  padding: 8px 15px;
  color: #fff;
  font-size: 13px;
}

.navigation .dropdown .dropdown-item:hover {
  background: transparent;
  color: #31BB9E;
}

.navigation .dropdown .dropdown-menu {
  border-radius: 0;
  background-color: #000;
  display: block;
  visibility: hidden;
  transition: .3s ease;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
}

@media (max-width: 992px) {
  .navigation .dropdown .dropdown-menu {
    display: none;
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
    transform-origin: unset;
  }
}

.navigation .dropdown .dropdown-menu.show {
  visibility: visible;
  opacity: 1;
  transform: scaleY(1);
}

@media (max-width: 992px) {
  .navigation .dropdown .dropdown-menu.show {
    display: block;
  }
}

.navbar-brand {
  height: auto;
  padding: 8px 0;
  color: #31BB9E;
}

.navbar-brand img {
  width: 150px;
}

.navbar-brand h1 {
  margin: 0;
  font-size: 25px;
}

.top-header {
  background: #000;
  padding: 10px 0;
  overflow: auto;
  white-space: nowrap;
}

.top-header::-webkit-scrollbar {
  height: 5px;
}

.top-header::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.top-header::-webkit-scrollbar-thumb {
  background-color: #252d34;
  outline: 2px solid #fff;
}

.currency-status {
  text-align: center;
}

.currency-status li {
  display: inline-block;
  margin: 0 10px;
}

.currency-status li:last-child a {
  border-right: 1px solid #444;
}

.currency-status li a {
  font-weight: normal;
  font-size: 14px;
  padding: 0 15px;
  display: inline-block;
  color: #cacaca;
  border-left: 1px solid #444;
}

.currency-status li a i {
  margin-left: 4px;
}

.currency-status li a .down-status {
  color: #ED522E;
}

.currency-status li a .up-status {
  color: #70D70D;
}

/*=================================================================
  HERO AREA
==================================================================*/
.hero-area {
  padding: 150px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../images/slider/slider-bg-2.jpg");
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

@media (max-width: 480px) {
  .hero-area {
    padding: 80px 0;
  }
}

.hero-area:before {
  content: '';
  background: rgba(49, 187, 158, 0.84);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.hero-area .block {
  color: #fff;
  padding-left: 30px;
}

@media (max-width: 480px) {
  .hero-area .block {
    padding: 0;
  }
}

.hero-area .block h2 {
  font-size: 48px;
  margin-bottom: 20px;
}

.hero-area .block p {
  color: #fff;
  margin-bottom: 20px;
}

.hero-area .block .list-inline {
  margin-top: 50px;
}

.hero-area .block .btn-main, .hero-area .block .btn-main-sm {
  margin-right: 8px;
}

.video-player iframe {
  width: 100%;
  height: 100%;
}

.video-player .play-icon {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.video-player .play-icon:hover i {
  transform: scale(1.1);
}

.video-player .play-icon i {
  margin-left: 3px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  background: #fff;
  color: #31BB9E;
  line-height: 80px;
  font-size: 30px;
  margin-left: 5px;
  cursor: pointer;
}

.hero-area-video {
  height: 100vh;
  position: inherit !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-area-video .block {
  color: #fff;
  text-align: center;
}

.hero-area-video .block h1 {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero-area-video .block p {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px;
  margin-bottom: 20px;
}

.hero-area-video .block .btn-main, .hero-area-video .block .btn-main-sm {
  margin-top: 20px;
}

.dark-bg {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

/*=================================================================
  About us section
==================================================================*/
.about {
  background: #fff;
}

.about h2 {
  font-size: 40px;
  font-weight: bold;
  margin-top: 0;
}

@media (max-width: 992px) {
  .about h2 {
    font-size: 25px;
  }
}

.about h4 {
  font-size: 14px;
  color: #31BB9E;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.about p {
  line-height: 1.6;
  margin-top: 20px;
}

.about .content {
  padding: 50px 0;
}

@media (max-width: 992px) {
  .about .content {
    padding: 5px 0;
  }
}

/*=================================================================
  About us 2 section
==================================================================*/
.about-2 {
  background: #F7F7F7;
}

.about-2 h2 {
  margin-bottom: 25px;
}

.about-2 .bitcoin-graph {
  width: 100%;
  height: 400px;
}

.about-2 .svg-container {
  overflow: hidden;
}

@media (max-width: 992px) {
  .about-2 .svg-container {
    width: 350px !important;
  }
}

@media (max-width: 768px) {
  .about-2 .svg-container {
    width: 450px !important;
  }
}

@media (max-width: 480px) {
  .about-2 .svg-container {
    width: 350px !important;
  }
}

@media (max-width: 400px) {
  .about-2 .svg-container {
    width: 300px !important;
  }
}

/*=================================================================
 Call To Action 1
==================================================================*/
.call-to-action {
  padding: 100px 0;
  background-color: #31BB9E;
  color: #fff;
}

.call-to-action h2 {
  line-height: 1.5;
}

.call-to-action p {
  color: #fff;
}

.call-to-action .btn-main, .call-to-action .btn-main-sm {
  margin-top: 20px;
  background: #fff;
  color: #31BB9E;
}

/*=================================================================
 Call To Action 2
==================================================================*/
.call-to-action-2 {
  padding: 100px 0;
  background-image: url("../images/call-to-action/call-to-action-bg.jpg");
  background-size: cover;
  color: #fff;
}

.call-to-action-2 h2 {
  line-height: 1.5;
}

.call-to-action-2 p {
  color: #fff;
}

.call-to-action-2 .btn-main, .call-to-action-2 .btn-main-sm {
  margin-top: 20px;
}

/*=================================================================
  Contact
  ==================================================================*/
.contact-form {
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .contact-form {
    margin-top: 20px;
  }
}

.contact-form .form-control {
  background-color: transparent;
  border: 1px solid #dedede;
  box-shadow: none;
  height: 45px !important;
  color: #0c0c0c;
  height: 38px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  border-radius: 3px;
}

.contact-form input:hover,
.contact-form textarea:hover,
.contact-form #contact-submit:hover {
  border-color: #31BB9E;
}

.contact-form #contact-submit {
  border: none;
  padding: 15px 0;
  width: 100%;
  margin: 0;
  background: #31BB9E;
  color: #fff;
  border-radius: 0;
  cursor: pointer;
}

.contact-form textarea.form-control {
  padding: 10px;
  height: 120px !important;
  outline: none;
}

.contact-details .contact-short-info {
  margin-top: 15px;
}

.contact-details .contact-short-info li {
  margin-bottom: 6px;
}

.social-icon li {
  display: inline-block;
  margin-right: 10px;
}

.social-icon li a {
  display: block;
  height: 30px;
  width: 30px;
  text-align: center;
  color: #31BB9E;
}

.social-icon li a i {
  display: inline-block;
  font-size: 20px;
  line-height: 50px;
  margin: 0;
}

.error {
  display: none;
  padding: 10px;
  color: #D8000C;
  border-radius: 4px;
  font-size: 13px;
  background-color: #FFBABA;
}

.success {
  background-color: #6cb670;
  border-radius: 4px;
  color: #fff;
  display: none;
  font-size: 13px;
  padding: 10px;
}

.map {
  position: relative;
}

.map #map {
  height: 400px;
  color: white;
}

.contact-meta-block {
  margin-top: 30px;
  padding: 40px 0;
  min-height: 250px;
  text-align: center;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.05);
}

.contact-meta-block > i {
  display: block;
  font-size: 50px;
  margin-bottom: 20px;
}

/*=================================================================
  Item counter section
==================================================================*/
.counters-item {
  padding-bottom: 30px;
  color: #444;
  position: relative;
  text-align: center;
}

.counters-item.kill-border:before {
  background: none;
}

.counters-item:before {
  background: #e5e5e5;
  content: "";
  margin-top: -27.5px;
  height: 55px;
  width: 1px;
  position: absolute;
  right: -15px;
  top: 50%;
}

.counters-item > div {
  margin: 6px 0;
}

.counters-item span {
  display: inline;
  font-size: 35px;
  font-weight: 700;
  line-height: 50px;
}

@media (max-width: 480px) {
  .counters-item span {
    font-size: 20px;
  }
}

.counters-item i {
  font-size: 40px;
  display: inline-block;
  color: #31BB9E;
}

.counters-item h3 {
  font-size: 18px;
  margin: 0;
  color: #666;
}

/*=================================================================
  Pricing section
==================================================================*/
.pricing-table .pricing-tab {
  text-align: center;
  margin-bottom: 25px;
  border: 1px solid #31BB9E;
  display: inline-block;
  padding: 2px;
  margin-bottom: 50px;
}

.pricing-table .pricing-tab li {
  display: inline-block;
}

.pricing-table .pricing-tab li a {
  padding: 10px 30px;
  color: #555;
  font-weight: bold;
  text-transform: uppercase;
}

.pricing-table .pricing-tab li .active {
  background: #31BB9E;
  border-radius: 0;
}

.pricing-table .pricing-item {
  margin-bottom: 15px;
  border-radius: 3px;
  padding: 0 0px 20px;
  text-align: center;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.09);
}

.pricing-table .pricing-item a.btn-main, .pricing-table .pricing-item a.btn-main-sm {
  text-transform: uppercase;
  margin-top: 20px;
}

.pricing-table .pricing-item h3 {
  color: #fff;
  font-size: 16px;
  padding: 15px;
  background: #31BB9E;
  font-weight: bold;
  font-family: "Roboto", sans-serif;
}

.pricing-table .pricing-item .price {
  font-size: 50px;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
}

.pricing-table .pricing-item .price span {
  display: inline-block;
}

.pricing-table .pricing-item .price .sup {
  top: -20px;
  font-weight: 300;
  font-size: 20px;
  position: relative;
}

.pricing-table .pricing-item .progress {
  margin-bottom: 25px;
  height: 3px;
}

.pricing-table .pricing-item .progress .progress-bar {
  background: #31BB9E;
}

.pricing-table .pricing-item .btn-main, .pricing-table .pricing-item .btn-main-sm {
  border-radius: 50px;
}

.pricing-table .pricing-item .pricing-body {
  padding: 30px;
}

.custom-pricing {
  margin-bottom: 30px;
  margin-top: 50px;
}

.custom-pricing p {
  margin-bottom: 5px;
}

.custom-pricing a {
  color: #31BB9E;
}

.custom-pricing-form h3 {
  margin-bottom: 20px;
}

.custom-pricing-form input, .custom-pricing-form button {
  height: 50px;
  border-radius: 0;
}

/*=================================================================
  Services section
==================================================================*/
.services .service-block {
  padding: 30px 20px;
}

@media (max-width: 480px) {
  .services .service-block {
    padding: 15px;
  }
}

.services .service-block .service-icon {
  margin-bottom: 15px;
}

.services .service-block i {
  font-size: 40px;
  color: #31BB9E;
  background: linear-gradient(100deg, #f9643d, #fe2a77);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.services .service-block h3 {
  color: #333;
  font-size: 18px;
}

.services .service-block p {
  color: #777;
}

/*=================================================================
  Services section 2
==================================================================*/
@media (max-width: 480px) {
  .service-2 .p-0 {
    padding: 0 15px !important;
  }
}

@media (max-width: 768px) {
  .service-2 .p-0 {
    padding: 0 15px !important;
  }
}

@media (max-width: 400px) {
  .service-2 .p-0 {
    padding: 0 15px !important;
  }
}

.service-2 .service-item {
  background: #fff;
  border: 1px solid #eee;
  margin-left: -1px;
  padding: 50px 20px;
  transition: all 0.3s ease 0s;
}

.service-2 .service-item .count {
  font-size: 80px;
  color: #f3f1f1;
  font-weight: 700;
}

.service-2 .service-item i {
  font-size: 30px;
  display: inline-block;
  color: #31BB9E;
  border-radius: 30px;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
  height: 55px;
  line-height: 55px;
  margin-bottom: 20px;
  width: 55px;
  transition: all 0.3s ease 0s;
}

.service-list .block {
  background: #31BB9E;
  color: #fff;
  padding: 30px;
}

.service-list .block i {
  font-size: 30px;
}

.service-list .block h3 {
  font-size: 22px;
  margin: 10px 0;
}

.service-list .block ul li {
  margin-bottom: 6px;
  color: #f9f9f9;
}

/*=================================================================
  Our skills
==================================================================*/
.team-skills .progress-block {
  margin-top: 40px;
}

.team-skills .progress-block span {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 14px;
}

.team-skills .progress-block .progress {
  box-shadow: none;
  border-radius: 0;
  height: 10px;
}

.team-skills .progress-block .progress .progress-bar {
  background: #31BB9E;
  box-shadow: none;
}

.clients-logo-slider {
  margin-top: 50px;
}

/*=================================================================
  Step section
==================================================================*/
.development-steps .step {
  padding: 80px 20px;
}

.development-steps .step h4 {
  font-size: 20px;
  font-weight: 600;
}

.development-steps .step h4 i {
  font-size: 72px;
  font-weight: 500;
  margin-right: 10px;
}

.development-steps .step p {
  color: #cccccc;
  font-size: 14px;
  line-height: 22px;
}

.development-steps .step-1 {
  background-color: #f2f6f8;
}

.development-steps .step-1 h4 {
  color: #212121;
}

.development-steps .step-1 p {
  color: #838383;
}

.development-steps .step-2 {
  background-color: #31BB9E;
}

.development-steps .step-2 h4, .development-steps .step-2 p {
  color: #fff;
}

.development-steps .step-3 {
  background: #26937c;
}

.development-steps .step-3 h4, .development-steps .step-3 p {
  color: #fff;
}

.development-steps .step-4 {
  background: #217e6b;
}

.development-steps .step-4 h4, .development-steps .step-4 p {
  color: #fff;
}

/*=================================================================
  Our Team
  ==================================================================*/
.team-member {
  background: #fff;
  margin-bottom: 15px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
}

.team-member .member-content {
  padding: 25px 0;
}

.team-member .member-content h3 {
  font-size: 20px;
  margin: 0 0 5px;
  color: #333;
}

.team-member .member-content span {
  font-size: 14px;
  color: #555;
}

.team-member .member-content p {
  color: #777;
  margin-top: 10px;
  padding: 0 15px;
}

.team-member .member-photo {
  overflow: hidden;
  position: relative;
}

.team-member .member-photo img {
  transition: all 0.6s ease 0s;
}

/*=================================================================
  Testimonial section
==================================================================*/
.testimonial {
  background: #31BB9E;
  position: relative;
}

.testimonial .item .block {
  background: #fff;
  margin: 10px;
  padding: 25px;
  border-radius: 4px;
}

.testimonial .item i {
  font-size: 40px;
  color: #31BB9E;
  margin-bottom: 30px;
  display: inline-block;
}

.testimonial .item .client-details p {
  font-size: 16px;
  font-weight: 200;
}

.testimonial .item .client-meta {
  margin-top: 8px;
}

.testimonial .item .client-meta h4 {
  margin-bottom: 4px;
  font-size: 16px;
}

.testimonial .item .client-meta p {
  font-size: 12px;
}

.testimonial .item .client-thumb {
  display: inline-block;
  border-radius: 100%;
  width: 50px;
  height: auto;
  border-radius: 50px;
}

/*=================================================================
  Latest Posts
==================================================================*/
.post-item {
  background: #fff;
  margin-bottom: 40px;
}

.post-item .post-thumb {
  margin-right: 10px;
}

.post-item .post-thumb img {
  height: auto;
  width: 100%;
}

.post-item .post-title {
  margin-top: 20px;
}

.post-item .post-title h3 {
  font-size: 23px;
}

.post-item .post-title h3 a {
  color: #000;
}

.post-item .post-meta {
  font-size: 14px;
  color: #888;
}

.post-item .post-meta a {
  color: #000;
  margin: 3px 0;
}

.post-item .post-content p {
  color: #757575;
  margin: 10px 0;
}

.post-item .btn-main, .post-item .btn-main-sm {
  font-size: 12px;
  margin-top: 10px;
  padding: 8px 20px;
  margin: 15px 0;
}

/*=================================================================
  Single Blog Page
==================================================================*/
#blog-banner {
  padding-bottom: 100px;
  padding-top: 150px;
}

#blog-page {
  margin: 0 0 40px;
}

.post-excerpt {
  padding: 0 20px;
  margin-bottom: 60px;
}

.post-excerpt h3 a {
  color: #000;
}

.post-excerpt blockquote {
  line-height: 22px;
  margin: 20px 0;
  font-size: 16px;
}

.single-blog {
  background-color: #fff;
  margin-bottom: 50px;
  padding: 20px;
}

.blog-subtitle {
  font-size: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.next-prev {
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  margin: 20px 0;
  padding: 25px 0;
}

.next-prev a {
  color: #000;
}

.next-prev a:hover {
  color: #31BB9E;
}

.next-prev .prev-post i {
  margin-right: 10px;
}

.next-prev .next-post i {
  margin-left: 10px;
}

.social-profile ul li {
  margin: 0 10px 0 0;
  display: inline-block;
}

.social-profile ul li a {
  color: #4e595f;
  display: block;
  font-size: 16px;
}

.social-profile ul li a i:hover {
  color: #31BB9E;
}

.comments-section {
  margin-top: 35px;
}

.author-about {
  margin-top: 40px;
}

.post-author {
  margin-right: 20px;
}

.post-author > img {
  border: 1px solid #dedede;
  max-width: 120px;
  padding: 5px;
  width: 100%;
}

.comment-list ul {
  margin-top: 20px;
}

.comment-list ul li {
  margin-bottom: 20px;
}

.comment-wrap {
  border: 1px solid #dedede;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
}

.comment-wrap .author-avatar {
  margin-right: 10px;
}

.comment-wrap .media .media-heading {
  font-size: 14px;
  margin-bottom: 8px;
}

.comment-wrap .media .media-heading a {
  color: #31BB9E;
  font-size: 13px;
}

.comment-wrap .media .comment-meta {
  font-size: 12px;
  color: #888;
}

.comment-wrap .media p {
  margin-top: 15px;
}

.comment-reply-form {
  margin-top: 80px;
}

.comment-reply-form input, .comment-reply-form textarea {
  height: 35px;
  border-radius: 0;
  box-shadow: none;
}

.comment-reply-form input:focus, .comment-reply-form textarea:focus {
  box-shadow: none;
  border: 1px solid #31BB9E;
}

.comment-reply-form textarea, .comment-reply-form .btn-main, .comment-reply-form .btn-main-sm {
  height: auto;
}

/*=================================================================
  Footer section
==================================================================*/
.top-footer {
  background-color: #222222;
  border-top: 1px solid #404040;
  padding: 100px 0 80px;
}

.top-footer h3 {
  font-size: 16px;
  line-height: 1.5;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.top-footer p {
  font-size: 13px;
  line-height: 30px;
  color: #bdbdbd;
  font-weight: 300;
  padding-right: 20px;
}

.top-footer li a {
  font-size: 13px;
  line-height: 30px;
  color: #bdbdbd;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: capitalize;
  transition: color .3s;
  font-family: "Playfair Display", serif;
  display: block;
}

.top-footer li a:hover {
  color: #31BB9E;
}

.footer-bottom {
  text-align: center;
  background-color: #1b1b1b;
  border-top: 1px solid #2c2c2c;
  padding: 30px 0;
}

.footer-bottom h5 {
  font-size: 12px;
  line-height: 1;
  color: #888888;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 7px;
  margin-bottom: 3px;
}

.footer-bottom h6 {
  font-size: 11px;
  line-height: 15px;
  color: #888888;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 2px;
  margin-bottom: 0;
}

.footer-bottom p {
  color: #656565;
}

.footer-bottom a {
  color: #31BB9E;
}

.subscribe-form .form-control {
  border-radius: 0;
}

/*=================================================================
  404
==================================================================*/
.page-404 h1 {
  font-size: 200px;
}
